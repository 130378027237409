import LZString from 'lz-string';

// var presidents = {
//     45: "Donald Trump",
//     44: "Barack Obama",
//     43: "George W. Bush"
// }


export async function newsletterSignup(email, firstName) {
    let dataURL = `https://sa3y6u1uxg.execute-api.us-east-1.amazonaws.com/production?e=${email}&f=${firstName}`;
    let response = await fetch(dataURL);
    return await response.json()    
}


export async function getPresidentDataAsync(metric, presidentList) {

    let dataURL = `https://o75zqjlcll.execute-api.us-east-1.amazonaws.com/production?metric=${metric}`;

    let cacheData = {};

    let runAPI = false;
    for (let i in presidentList) {

        let lsData = localStorage.getItem(`data_${metric}_${presidentList[i]}`)

        if (lsData) {            
            lsData = JSON.parse(LZString.decompressFromUTF16(lsData));
            cacheData[presidentList[i]] = lsData.data;
            continue;
        }

        runAPI = true;
        dataURL += `&president=${presidentList[i]}`;
    }

    let data = {};
    if (runAPI === true) {
        let response = await fetch(dataURL);

        data = await response.json()

        for (const presidentNumber in data) {
            localStorage.setItem(`data_${metric}_${presidentNumber}`, LZString.compressToUTF16(JSON.stringify({ date: new Date(), data: data[presidentNumber] })));
        }

        for (let i in cacheData) {
            data[i] = cacheData[i];
        }

    }
    else {
        data = cacheData;
    }





    var maxDataLength = 0;

    let headerRow = ['Index']
    for (const id in data) {
        headerRow.push(data[id][0].name)
        if (data[id].length > maxDataLength) maxDataLength = data[id].length;
    }

    var returnData = [headerRow];

    for (let i = 1; i < maxDataLength; i++) {
        let dataRow = [`${i}`];

        for (const id in data) {

            if (typeof (data[id][i]) != 'undefined')
                dataRow.push(data[id][i].president_percent_gain * 100)
            else
                dataRow.push(null);
        }
        returnData.push(dataRow)

    }
    return returnData;
}
