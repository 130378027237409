import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Col, Row, Modal, Form } from "react-bootstrap";
import newsletterStyles from "./newsletter.module.css";
import { newsletterSignup } from "../../services/ff";

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      email: "",
      firstName: "",
      error: false
    };
    
  }

  handleOpen() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false, error: false, email: "", firstName: "" });
  }

  async handleSignup() {
    this.setState({ error: false });
    let data = await newsletterSignup(this.state.email, this.state.firstName);
    if (data === "error") {
      this.setState({ error: true });
    } else {
      this.handleClose();
    }
  }

  render() {
    return (
      <div>
        <div
          className={newsletterStyles.newsletter + " " + ["newsletter-sign-up"]}
        >
          <Row>
            <Col md="7">
              Don't miss the latest data or other NON-OPINION updates from Facts
              First
            </Col>
            <Col md="5">
              <Button
                className={newsletterStyles.newsletterButton}
                onClick={() => this.handleOpen()}
                style={{ textAlign: "left" }}
              >
                Sign up for our newsletter{" "}
                <FontAwesomeIcon
                  width="0"
                  icon={faArrowAltCircleRight}
                  style={{ float: "right", fontSize: "24px" }}
                />
              </Button>
            </Col>
          </Row>
        </div>

        <Modal show={this.state.show} onHide={() => this.handleClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Want to see the facts more often?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Sign up for the Facts First newsletter to receive weekly updates and
            insights on performance of Trump and other U.S. presidents
            <Form style={{ marginTop: "25px" }}>
              <Form.Group style={{ marginBottom: "25px" }}>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={this.state.firstName}
                  onChange={fn => this.setState({ firstName: fn.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  value={this.state.email}
                  onChange={em => this.setState({ email: em.target.value })}
                />
              </Form.Group>
            </Form>
            {this.state.error && (
              <Alert variant="danger">
                Problem Signing Up, Verify Email Address
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="orange" onClick={() => this.handleSignup()}>
              Sign Up
            </Button>
            <Button variant="danger" onClick={() => this.handleClose()}>
              No Thanks
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default Newsletter;
